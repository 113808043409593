import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import DemoCTA from '../components/demoCta';
import Layout from '../components/layout';
import icons from '../components/icons';

export default function Pricing({ data }) {
  // use Heroicons to make things work.
  const nodes = data.allContentfulProductHeadlines.edges.map((x) => x.node);
  nodes.sort((a, b) => a.sortKey - b.sortKey);

  return (
    <Layout
      title="Products"
      mainClasses="py-16 bg-gray-50 overflow-hidden lg:py-24"
    >
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl divide-y divide-brandgreen">
        <div className="relative pb-6 lg:pb-12 sm:pb-8">
          <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            A better way to keep your riders moving
          </h3>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
            Adapta enhances your transit agency's infrastructure with
            data-driven get to where they need to go, in a way that's right for
            them.
          </p>
        </div>

        {nodes.map(({ headline, blurb, highlights, image }, i) => {
          const isEven = i % 2 === 0;
          return (
            <div
              key={i}
              className={`relative py-6 lg:py-12 sm:py-8 ${isEven ? '' : ''}`}
            >
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div className={isEven ? 'lg:col-start-2' : 'lg:col-start-1'}>
                  <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                    {headline}
                  </h4>
                  <p className="mt-3 text-lg leading-7 text-gray-500">
                    {blurb.blurb}
                  </p>
                  <ul className="mt-10">
                    {highlights.map(({ icon, title, description }, j) => {
                      return (
                        <li key={j} className={j === 0 ? undefined : 'mt-10'}>
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-brandblue text-white">
                                {icons[icon] || icons['default']}
                              </div>
                            </div>
                            <div className="ml-4">
                              <h5 className="text-lg leading-6 font-medium text-gray-900">
                                {title}
                              </h5>
                              <p className="mt-2 text-base leading-6 text-gray-500">
                                {description.description}
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {image && (
                  <div
                    className={`mt-10 -mx-4 relative lg:mt-0 ${
                      isEven ? 'lg:col-start-1' : 'lg:col-start-2'
                    }`}
                  >
                    <GatsbyImage
                      image={image.localFile.childImageSharp.gatsbyImageData}
                      className="relative mx-auto block"
                      style={{ display: 'inherit' }}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div className="mt-10">{DemoCTA()}</div>
    </Layout>
  );
}

export const query = graphql`
  {
    allContentfulProductHeadlines {
      edges {
        node {
          headline
          blurb {
            blurb
          }
          sortKey
          highlights {
            title
            icon
            description {
              description
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 490)
              }
            }
          }
        }
      }
    }
  }
`;
